import {twMerge} from '@/stylesheets/twMerge';
import ArrowRight from '@/components/brochureV2/icons/ArrowRight';
import type {LinkComponent} from '@/types';

export default function Link({
  link,
  className,
  ...rest
}: {
  link: LinkComponent;
  className?: string;
}) {
  if (!link.url) {
    return null;
  }

  return (
    <a
      href={link.url}
      className={twMerge(
        [
          'text-base overflow-hidden font-bold group leading-6',
          'default:inline-flex',
          'hover:text-zinc-700',
          'font-aktivgroteskextended',
        ],
        className,
      )}
      {...rest}
    >
      <span className="underline group-hover:no-underline">{link.text}</span>
      <ArrowRight className="reduced-motion:group-hover:translate-x-0 ml-1.5 h-6 w-6 origin-left translate-x-0 self-center opacity-100 transition-all duration-500 will-change-transform group-hover:translate-x-full group-hover:opacity-0" />
    </a>
  );
}
