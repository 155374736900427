import Typography from '@/components/base/elements/Typography/Typography';
import SubscribeForm from '@/components/pages/blog/SubscribeForm/SubscribeForm';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

import {
  disclaimerStyles,
  inputWrapperStyles,
  formWrapperStyles,
} from './styles';

interface SuccessMessage {
  heading: string;
  contentHtml: string;
}

interface CtaProps {
  label?: string;
  text: string;
  emailPlaceholder: string;
  successMessage?: SuccessMessage;
}

export interface CtaGroupProps {
  cta?: CtaProps;
  subscriptionId?: string;
  disclaimerHtml?: React.ReactNode;
  className?: string;
  center?: boolean;
  mode?: 'light' | 'dark';
  blogType?: BLOG_PAGE_TYPE;
}

export default function CTA({
  cta,
  subscriptionId,
  className,
  center,
  disclaimerHtml,
  mode = 'light',
  blogType = BLOG_PAGE_TYPE.BLOG,
}: CtaGroupProps) {
  return (
    <>
      {cta && (
        <div className={className} data-mode={mode} data-center={center}>
          {subscriptionId && (
            <SubscribeForm
              mode={mode}
              buttonText={cta.text}
              placeholder={cta.emailPlaceholder}
              label={cta?.label}
              inputWrapperClass={inputWrapperStyles({mode, center})}
              formWrapperClass={formWrapperStyles({mode, center})}
              subscriptionId={subscriptionId || ''}
              successMessage={cta?.successMessage}
              blogType={blogType}
            />
          )}
          {disclaimerHtml && (
            <Typography
              size="body-sm"
              className={disclaimerStyles({center, mode})}
              as="p"
            >
              {disclaimerHtml}
            </Typography>
          )}
        </div>
      )}
    </>
  );
}
