import {twMerge} from '@/stylesheets/twMerge';
import type {HeadingAs, Mode} from '@/components/base/types';
import type {HeadingGroupContentProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {ButtonGroupContentProps} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import type {CtaGroupProps} from '@/components/pages/blog/components/CTA/CTA';
import CTA from '@/components/pages/blog/components/CTA/CTA';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export * from './style';

interface ConversionProps
  extends HeadingGroupContentProps,
    ButtonGroupContentProps,
    CtaGroupProps {
  className?: string;
  headingClassName?: string;
  buttonsClassName?: string;
  ctaClassName?: string;
  mode?: Mode;
  blogType?: BLOG_PAGE_TYPE;
}

export default function Conversion({
  className,
  headingClassName,
  buttonsClassName,
  ctaClassName,
  cta,
  subscriptionId,
  mode = 'light',
  center = true,
  kicker,
  headingHtml,
  subheadHtml,
  buttons,
  disclaimerHtml,
  blogType = BLOG_PAGE_TYPE.BLOG,
}: ConversionProps) {
  const headingGroupProps = {
    className: headingClassName,
    headingAs: 'span' as HeadingAs,
    kicker,
    headingHtml,
    subheadHtml,
    center,
    mode,
  };
  const buttonGroupProps = {
    className: twMerge('mt-md', buttonsClassName),
    buttons,
    disclaimerHtml,
    center,
    mode,
    size: 'large' as 'large' | 'small',
  };
  const CtaProps = {
    className: ctaClassName,
    subscriptionId,
    cta,
    disclaimerHtml,
    center,
    mode,
    blogType,
  };

  return (
    <div className={className}>
      <HeadingGroup {...headingGroupProps} />
      {buttons?.length > 0 && <ButtonGroup {...buttonGroupProps} />}
      <CTA {...CtaProps} />
    </div>
  );
}
