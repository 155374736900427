import {cva} from 'class-variance-authority';

export const ButtonStyles = cva(
  [
    'w-full mt-2 sm:mt-0 md:text-base py-4 sm:py-3 md:px-7 disabled:!bg-[#e5e7eb] whitespace-nowrap',
    'hover:border-white hover:ring-0 hover:bg-[#62ba6b] focus:bg-[#00740B] active:bg-[#00740B] border-none',
  ],
  {
    variants: {
      isSuccess: {
        true: 'disabled:text-black',
      },
    },
  },
);
