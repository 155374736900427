import {cva} from 'class-variance-authority';

export const formWrapperStyles = cva(
  'flex w-full flex-col justify-center sm:w-[406px] [&_button]:bg-[#7BE986]',
  {
    variants: {
      center: {
        true: 'items-center m-auto',
        false: 'items-start',
      },
      mode: {
        light: 'text-black',
        dark: 'text-white',
      },
    },
  },
);

export const inputWrapperStyles = cva(
  'box-border flex h-14 w-full justify-between rounded-full border border-shades-60 border-[#52525B] pl-6 pr-1',
  {
    variants: {
      center: {
        true: 'items-center',
        false: 'items-start',
      },
      mode: {
        light: 'text-black bg-white',
        dark: 'text-white bg-white',
      },
    },
  },
);

export const disclaimerStyles = cva('pt-sm sm:w-[406px] w-full', {
  variants: {
    center: {
      true: 'text-center m-auto',
      false: 'items-left',
    },
    mode: {
      light: 'text-black',
      dark: 'text-white',
    },
  },
  defaultVariants: {
    center: false,
    mode: 'light',
  },
});
