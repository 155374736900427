import type {SVGAttributes} from 'react';

export default function Inbox(props: SVGAttributes<SVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 3.582 12.418 0 8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8ZM7 11C7 11.5523 7.44772 12 8 12C8.55229 12 9 11.5523 9 11V9C9 8.44771 8.55229 8 8 8C7.44772 8 7 8.44771 7 9V11ZM7 5C7 5.55228 7.44772 6 8 6C8.55229 6 9 5.55228 9 5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
