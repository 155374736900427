import cn from 'classnames';
import {useNavigation, useLocation} from '@remix-run/react';
import {useCallback, useState, useId} from 'react';
import type {FormEvent, ReactNode} from 'react';
import {withZod} from '@remix-validated-form/with-zod';
import {ValidatedForm} from 'remix-validated-form';
import {z} from 'zod';

import {useTranslations} from '@/components/shared/Page/I18N';
import Checkmark from '@/components/brochureV2/icons/Checkmark';
import InfoMinor from '@/components/brochureV2/icons/InfoMinor';
import Typography from '@/components/base/elements/Typography/Typography';
import FormButton from '@/components/base/elements/FormButton/FormButton';
import useIntlLocale from '@/hooks/useIntlLocale';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {FormTypes} from '@/enums';

import {ButtonStyles} from './style';

interface SubscribeFormProps {
  buttonText: string | undefined;
  className?: string;
  mode: string;
  inputWrapperClass?: string;
  formWrapperClass?: string;
  subscriptionId?: string;
  label?: string;
  listId?: string;
  placeholder?: string;
  blogType?: BLOG_PAGE_TYPE;
  successMessage?: {
    heading: string;
    contentHtml: string;
  };
}

type StatusTextProps = {
  children: ReactNode;
};

function ErrorText({children}: StatusTextProps) {
  return (
    <span className="py-2 pl-6 text-xs">
      <InfoMinor className="text-state-error inline mr-2 align-bottom" />
      {children}
    </span>
  );
}

// Hidden field that, on submission will prevent the submission from being sent.
// The mechanism for hiding it is delicate, It needs to be fillable by bots, without being autocompleted
// See: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill for details
function HoneyPotField() {
  const uniqueId = useId();

  return (
    <input
      className="sr-only"
      id={uniqueId}
      name="address"
      aria-label="address"
    />
  );
}

const formValidator = withZod(
  z.object({
    email: z
      .string()
      .min(1, {message: 'subscribe.errors.email'})
      .email('subscribe.errors.email'),
  }),
);

const Button = ({
  children,
  disabled = false,
  success = false,
}: {
  children: string;
  disabled?: boolean;
  success?: boolean;
}) => {
  const {t} = useTranslations();

  return (
    <FormButton
      className={ButtonStyles({isSuccess: success})}
      intent="secondary"
      type="submit"
      disabled={disabled || success}
      componentName="join-waitlist-form-button"
    >
      {success ? (
        <>
          <Checkmark className="inline mr-2 align-baseline" width="14px" />
          {t('global:waitlistForm.successMessage')}
        </>
      ) : (
        children
      )}
    </FormButton>
  );
};

export default function SubscribeForm({
  buttonText,
  placeholder,
  label,
  formWrapperClass,
  inputWrapperClass,
  subscriptionId,
  successMessage,
  blogType = BLOG_PAGE_TYPE.BLOG,
}: SubscribeFormProps) {
  const {t, localizePath} = useTranslations();
  const navigation = useNavigation();
  const location = useLocation();
  const locale = useIntlLocale();
  const uniqueId = useId();
  const [submitResponse, setSubmitResponse] = useState<{success: boolean}>();
  const inputId = 'email-input-' + useId();

  const defaultPlaceholder = t('global:waitlistForm.placeholder');
  const defaultButtonText = t('global:waitlistForm.buttonText');

  const handleSubmit = useCallback(
    async (_data: any, event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);
      const body = new URLSearchParams(formData as any);

      const response = await fetch(form.action, {
        method: form.method,
        body,
      });

      if (!response.ok) {
        throw new Error(
          '[BlogDataSubscribe] Something went wrong when submitting the form data',
        );
      }

      const responseJSON: {success: boolean} = await response.json();
      setSubmitResponse(responseJSON);
    },
    [],
  );

  const formSuccessMessage = (
    <div
      aria-live="polite"
      className="p-6 bg-white text-black rounded-2xl shadow-light space-y-2 text-left mt-4 w-full"
    >
      <Typography as="p" size="t7">
        {successMessage?.heading}
      </Typography>
      <Typography as="p" size="p">
        {successMessage?.contentHtml}
      </Typography>
    </div>
  );

  return (
    <>
      {label && (
        <Typography as="p" className="sr-only">
          {label}
        </Typography>
      )}
      <ValidatedForm
        method="post"
        action={localizePath('/blog/api/subscribe')}
        onSubmit={handleSubmit}
        validator={formValidator}
        className={formWrapperClass}
      >
        <div
          id={uniqueId}
          data-component-name="waitlist-form-input-wrapper"
          className={cn(inputWrapperClass, {
            'border-state-error': submitResponse && !submitResponse.success,
          })}
        >
          <label htmlFor={inputId} className="sr-only">
            {placeholder ?? defaultPlaceholder}
          </label>
          <input
            id={inputId}
            className="text-shade-70 h-14 w-full bg-transparent outline-none placeholder:text-black placeholder:font-normal"
            type="email"
            spellCheck="false"
            autoComplete="email"
            name="email"
            placeholder={placeholder ?? defaultPlaceholder}
            disabled={
              navigation.state === 'submitting' || submitResponse?.success
            }
          />
          <input type="hidden" name="subscriptionId" value={subscriptionId} />
          <input type="hidden" name="signup_page" value={location.pathname} />
          <input type="hidden" name="locale" value={locale} />
          <input type="hidden" name="blogHandle" value={blogType} />
          <input type="hidden" name="form_type" value={FormTypes.Subscribe} />

          <div className="hidden sm:block">
            <Button
              success={submitResponse?.success}
              disabled={navigation.state === 'submitting'}
            >
              {buttonText ?? defaultButtonText}
            </Button>
          </div>
        </div>
        <div className="block w-full sm:hidden">
          <Button
            success={submitResponse?.success}
            disabled={navigation.state === 'submitting'}
          >
            {buttonText ?? defaultButtonText}
          </Button>
        </div>

        <HoneyPotField />

        {submitResponse && !submitResponse.success && (
          <ErrorText>{t('global:waitlistForm.errors.general')}</ErrorText>
        )}

        {submitResponse?.success && formSuccessMessage}
      </ValidatedForm>
    </>
  );
}
